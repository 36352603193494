<template>
  <v-card elevation="1">
    <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
      <v-row class="px-6 pt-2">
        <v-col cols="4">
          <v-text-field
            v-model="formData.nome"
            placeholder="Digite o nome"
            outlined
            dense
            :clearable="true"
            label="Nome"
            required
            :rules="rules.genericRules"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="px-6 pb-4">
      <v-btn
        depressed
        color="fmq_gray"
        dark
        @click="send"
        :loading="loadingBtn"
        >{{ isEdit ? "Editar Tag" : "Criar Tag" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { rules } from "@/utils/rules.js";
export default {
  name: "TagsFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    formData: {
      nome: null,
    },
    rules: rules,
    valid: true,
    isEdit: false,
  }),
  created() {
    if (this.$route.name === "TagsEditar") {
      this.formData = this.tag;
      this.isEdit = true;
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    send() {
      if (this.formValid) this.$emit("send", this.formData);
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style></style>
